import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { environment } from "src/environments/environment";

export interface ResponseModel {
    success: boolean;
    code:string
    data:any;
}

@Injectable({ providedIn: 'root' })
export class ApiService {

    private API_URL = environment.API_URL;

    constructor(private _http:HttpClient,private translateService:TranslateService) { }

    post({url,parameters}):Promise<ResponseModel> {
        return new Promise((resolve,rejects)=>{
            try{
                var call_url = this.API_URL + url;
                var langIndex = 0;
                if(this.translateService.currentLang){
                    langIndex = this.translateService.langs.indexOf(this.translateService.currentLang);
                }
                let headers = new HttpHeaders().append('lang',langIndex.toString()).append('type','7')
                this._http.post(call_url, parameters,{headers}).toPromise().then(responseData=>{
                    if(responseData['success']){
                        resolve({success:responseData['success'],code:responseData['message'],data:responseData})
                    }else{
                        resolve({success:responseData['success'],code:responseData['error_code'],data:responseData})
                    }
                })
            }catch(err){
                resolve({success:false,code:"2003",data:null});
            }
        });
    }

    get({url,parameters}):Promise<ResponseModel> {
        return new Promise((resolve,rejects)=>{
            try{
                var call_url = this.API_URL + url;
                this._http.get(call_url, parameters).toPromise().then(responseData=>{
                    if(!responseData){
                        resolve({success:true,code:null,data:null})
                    }else if(responseData['success']){
                        resolve({success:responseData['success'],code:responseData['message'],data:responseData})
                    }else{
                        resolve({success:responseData['success'],code:responseData['error_code'],data:null})
                    }
                })
            }catch(err){
                resolve({success:false,code:"2003",data:null});
            }
        });
    }
    
    
}