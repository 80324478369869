export class SetttingModel {
    admin_contact_email: string = null;
    admin_contact_phone_number: string = null;
    app_name: string = null;
    _id: string = null;
    is_user_sms_verification:boolean = false;
    is_user_mail_verification:boolean = false;
    is_allow_contactless_delivery:boolean= false;
    is_user_login_by_social: boolean =false;
    is_confirmation_code_required_at_complete_delivery: boolean =false;
    is_confirmation_code_required_at_pickup_delivery: boolean =false;
    is_use_aws_bucket: boolean =false;
    tip_type:number = 0;
    store_base_url: string = ''
    is_allow_user_to_give_tip: boolean = false
    is_allow_bring_change_option: boolean = false
}