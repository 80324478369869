import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { apiColletions } from "../constants/api_collection";
import { UserModel } from "../models/user.model";
import { WalletHistory } from "../models/wallet_history.model";
import { ApiService } from "./api.service";

@Injectable({ providedIn: 'root' })
export class ProfileService {

    constructor(private _api:ApiService){}

    fetch_profile({user_id,server_token}):Promise<UserModel>{
        return new Promise((resolve,rejects)=>{
            try{
                var parameters = {user_id,server_token};
                this._api.post({url:apiColletions.get_user_detail,parameters}).then((response)=>{
                    if(response.success){
                        resolve(response.data.user);
                    }else{
                        resolve(null);
                    }
                })
            }catch(err){
                resolve(null);
            }
        })
    }

    update_profile(profileForm):Promise<Boolean>{
        return new Promise((resolve,rejects)=>{
            try{
                this._api.post({url:apiColletions.user_update,parameters:profileForm}).then((response)=>{
                    if(response.success){
                        resolve(true);
                    }else{
                        resolve(false);
                    }
                })
            }catch(err){
                resolve(false);
            }
        })
    }


    get_wallet_history({user_id,server_token}):Promise<Array<WalletHistory>>{
        return new Promise((resolve,rejects)=>{
            try{
                var parameters = {id: user_id,server_token: server_token,type: 7}
                this._api.post({url:apiColletions.get_wallet_history,parameters}).then((response)=>{
                    if(response.success){
                        resolve(response.data.wallet_history);
                    }else{
                        resolve([]);
                    }
                })
            }catch(err){
                resolve([]);
            }
        })
    }

    get_fav_addresses({user_id,server_token}):Promise<Array<WalletHistory>>{
        return new Promise((resolve,rejects)=>{
            try{
                var parameters = {id: user_id,server_token: server_token,type: 7}
                this._api.post({url:apiColletions.get_favoutire_addresses,parameters}).then((response)=>{
                    if(response.success){
                        resolve(response.data.favourite_addresses);
                    }else{
                        resolve([]);
                    }
                })
            }catch(err){
                resolve([]);
            }
        })
    }

    add_fav_address(parameters):Promise<Array<WalletHistory>>{
        return new Promise((resolve,rejects)=>{
            try{
                this._api.post({url:apiColletions.add_favourite_address,parameters}).then((response)=>{
                    if(response.success){
                        resolve(response.data.favourite_addresses);
                    }else{
                        resolve([]);
                    }
                })
            }catch(err){
                resolve([]);
            }
        })
    }

    delete_fav_address(parameters):Promise<Array<WalletHistory>>{
        return new Promise((resolve,rejects)=>{
            try{
                this._api.post({url:apiColletions.delete_favourite_address,parameters}).then((response)=>{
                    if(response.success){
                        resolve(response.data.favourite_addresses);
                    }else{
                        resolve([]);
                    }
                })
            }catch(err){
                resolve([]);
            }
        })
    }

}