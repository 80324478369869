import { Injectable } from "@angular/core";
import { apiColletions } from "../constants/api_collection";
import { ApiService } from "./api.service";
import {TableBookingModel} from '../models/table_booking.model'
import { TableModel } from '../models/table.model'

@Injectable({ providedIn: 'root' })
export class TableService {


    private _tableCart = new TableModel();
    is_table_booked:boolean = false;
    constructor(private _api:ApiService){}


    get tableCart():TableModel{
        return this._tableCart;
    }

    set tableCart(tableCart:TableModel){        
        this._tableCart = tableCart;
    }

    fetch_table_details({store_id,server_token}):Promise<TableBookingModel>{
        return new Promise((resolve,rejects)=>{
            try{
                var parameters = {store_id,server_token};
                this._api.post({url:apiColletions.fetch_table_details,parameters}).then((response)=>{
                    if(response.success){
                        this.tableCart = response.data.data
                        resolve(response.data.data);
                    }else{
                        resolve(null);
                    }
                })
            }catch(err){
                resolve(null);
            }
        })
    }


    list_table({store_id,server_token}):Promise<any>{
        return new Promise((resolve,rejects)=>{
            try{
                var parameters = {store_id,server_token};
                this._api.post({url:apiColletions.list_table,parameters}).then((response)=>{
                    if(response.success){
                        resolve(response.data.data);
                    }else{
                        resolve(null);
                    }
                })
            }catch(err){
                resolve(null);
            }
        })
    }

   reserve_table(json):Promise<any>{
       return new Promise((resolve, reject) => {
           try {
               var parameters = json
               this._api.post({url: apiColletions.reserve_table, parameters}).then((response) => {
                   if(response.success){
                       resolve(true)
                   } else {
                        reject(false)
                   }
               })
           } catch {
               reject(false)
           }
       })
   }

   fetch_table(json): Promise<any>{
       return new Promise((resolve, reject) => {
           try{
               var parameters = json
               this._api.post({url: apiColletions.fetchTableDetails, parameters}).then((response) => {
                   console.log(response)
                   if(response.success){
                       resolve(response)
                   } else {
                       reject(false)
                   }
               })
           } catch {
               reject(false)
           }
       })
   }

}