import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './authguards/auth.guard';


const routes: Routes = [
  { path: 'delivery', loadChildren: () => import('./views/home-page/homepage.module').then(m => m.HomePageModule), pathMatch:'full' },
  { path: 'store/:store_id/:table_id/:token',loadChildren: () => import('./views/landing/landing.module').then(m => m.LandingModule) },
  { path: 'store',loadChildren: () => import('./views/landing/landing.module').then(m => m.LandingModule) },
  { path: 'store/:id',loadChildren: () => import('./views/landing/landing.module').then(m => m.LandingModule) },
  { path: '',loadChildren: () => import('./views/info/info.module').then(m => m.InfoModule) },
  { path: 'checkout', loadChildren: () => import('./views/checkout/checkout.module').then(m => m.CheckoutModule) },
  { path: 'help', loadChildren: () => import('./views/help/help.module').then(m => m.HelpModule) },
  { path: 'courier-order', loadChildren: () => import('./views/courier-order/courier-order.module').then(m => m.CourierModule) },
  { path: 'courier-order-comming-soon', loadChildren: () => import('./views/courier/courier-soon/courier-soon.module').then(m => m.CourierSoonModule) },
  { path: 'privacy-policy', loadChildren: () => import('./views/privacy/privacy.module').then(m => m.PrivacyModule) },
  { path: 'terms-and-conditions', loadChildren: () => import('./views/terms/terms.module').then(m => m.TermsModule) },
  { path: 'account', canActivate:[AuthGuard],canActivateChild:[AuthGuard] ,loadChildren: () => import('./views/account-detail/account-detail.module').then(m => m.AccountModule) },
  { path: 'order', canActivate:[AuthGuard],canActivateChild:[AuthGuard] ,loadChildren: () => import('./views/order-detail/order-detail.module').then(m => m.OrdersModule) },
  { path: 'w-history', canActivate:[AuthGuard],canActivateChild:[AuthGuard] ,loadChildren: () => import('./views/wallet-history/wallet-history.module').then(m => m.WalletHistoryModule) },
  { path: 'f-list', canActivate:[AuthGuard],canActivateChild:[AuthGuard] ,loadChildren: () => import('./views/favourites-list/favourite-list.module').then(m => m.FavouriteListModule) },
  { path: 'legal/:type',loadChildren: () => import('./views/privacy-terms/privacy-terms.module').then(m => m.PrivacyTermsModule) },

  { path: '**', redirectTo:'homepage',pathMatch:'full' },
  { path: 'delete-account', loadChildren: () => import('./views/account-delete/account-delete.module').then(m => m.AccountDeleteModule) },
 // { path: 'privacy_policy', loadChildren: () => import('./views/privacy/privacy.module').then(m => m.PrivacyModule) },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }