import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RoundPipe } from './round.pipe'
import { SearchPipe } from './search.pipe';
import { LangPipe } from './lang.pipe';

@NgModule({
  declarations: [RoundPipe,SearchPipe,LangPipe],
  imports: [CommonModule],
  exports: [RoundPipe,SearchPipe,LangPipe],
})
export class PipeModule {}
