import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { environment } from "src/environments/environment";
import { UserModel } from "../models/user.model";
import { Helper } from "../shared/helper";
import { AuthService } from "./auth.service";
import { CartService } from "./cart.service";
import { CommonService } from "./common.service";
import { LoaderService } from "./loader.service";
import { LocationService } from "./location.service";

declare var $:any;

@Injectable({ providedIn: 'root' })
export class ConfigService {

    constructor(private _locationService:LocationService,private translateService:TranslateService,private _commonService:CommonService,private _loaderService:LoaderService,private _cartService:CartService,private _helper:Helper,private _authService:AuthService){}

    _initApp(){

        return new Promise(async (resolve,rejects)=>{
            try{
                console.log('App Initialization Start....')
                this._loaderService.isLoading = true;
                await this._helper.loadScript('/assets/js/custom.js')
                await this._helper.loadScript('https://unpkg.com/sweetalert/dist/sweetalert.min.js')
                await this.storeCartToken();
                await this._commonService.get_setting_detail();
                await this.storeUserData();
                // await this._locationService.set_current_location()
                this._loaderService.isLoading = false;
                console.log('App Initialization End....')
                resolve(true);                
            }catch(err){
                rejects(true);
            }
        })
    }

    

    storeUserData():Promise<boolean>{
        var userData:UserModel = JSON.parse(localStorage.getItem('userData'));
        if(userData === null){
            return this._authService.user_logout();
        }else{
            return this._authService.user_autologin({user_id:userData._id,server_token:userData.server_token,setting:this._commonService.settings})
        }
    }


    loadStripe():Promise<boolean>{
        return new Promise((resolve,rejects)=>{
            let script = document.createElement('script');
            script.type = 'text/javascript';
            script.innerHTML = "var stripe = Stripe('"+environment.STRIPE_KEY+"'); var elements = stripe.elements();";
            document.getElementsByTagName('head')[0].appendChild(script);
            resolve(true);
        })
    }
    

    storeCartToken():Promise<boolean>{
        return new Promise((resolve, reject) => {
            var cart_unique_token = localStorage.getItem('cart_unique_token');
            if(cart_unique_token === null){
                var new_token = this._helper.generate_new_uuid;
                localStorage.setItem('cart_unique_token',new_token);
                this._helper.cart_unique_token = new_token;
                this._cartService.cart_unique_token = new_token;
            }else{
                this._helper.cart_unique_token = cart_unique_token;
                this._cartService.cart_unique_token = cart_unique_token;
            }
            resolve(true)
        });
    }
    

   

}