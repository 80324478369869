export class TableModel {
    _id: string = null;
    is_bussiness: boolean = false;
    is_user_can_book: boolean = false;
    store_id: String = "";
    table_code: String = "";
    table_max_person: Number = 2;
    table_min_person: Number = 1;
    table_no: Number = 2;
    table_qrcode: String = "";
    table_token: String = "";
    unique_id: Number = 0;
    booking_fees: Number= 0 ;
}