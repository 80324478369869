import { TranslateService } from '@ngx-translate/core';
import { Injectable } from "@angular/core";
import { ToastrService } from "ngx-toastr";

@Injectable({ providedIn: 'root' })
export class NotifiyService{

    constructor(private toaster: ToastrService, private translateService: TranslateService){}

    showNotification( type: string, message: string ): void {

      if(type === 'success'){
        this.toaster.success(this.translateService.instant('label-title.success'), message);
      }else{
        // this.toaster.error('error', message);
        this.toaster.error(this.translateService.instant('label-title.error'), message);
      }


    }

}
