import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { apiColletions } from '../constants/api_collection';
import {
  Cart,
  cartAddress,
  CartData,
  cartProductsModel,
  cartSpecificationGroupsModel,
  ItemModel,
} from '../models/cart.model';
import { OrderPayment } from '../models/order_payment';
import { Helper } from '../shared/helper';
import { ApiService, ResponseModel } from './api.service';
import { AuthService } from './auth.service';
import { TableService } from './table.service';

export enum DELIVERY_TYPE {
  ZERO,
  DELIVERY,
  COURIER,
  TABLE,
}

@Injectable({ providedIn: 'root' })
export class CartService {
  private _user_cart: Cart = new Cart();
  private _edit_cart: Cart = new Cart();
  cart_unique_token = '';
  private cartSubject = new BehaviorSubject<Cart>(null);
  cartObservable: Observable<Cart> = this.cartSubject.asObservable();
  currency;
  store_taxes: any = [];

  constructor(
    private _api: ApiService,
    private _authService: AuthService,
    private _helper: Helper,
    private _tableService: TableService
  ) {}

  get user_cart(): Cart {
    return this._user_cart;
  }

  set user_cart(cart: Cart) {
    this._user_cart = cart;
  }

  get edit_cart(): Cart {
    return this._edit_cart;
  }

  set edit_cart(cart: Cart) {
    this._edit_cart = cart;
  }

  public get_cart(): Promise<ResponseModel> {
    return new Promise((resolve, reject) => {
      try {
        let json = {
          user_id: this._authService.user._id,
          server_token: this._authService.user.server_token,
          cart_unique_token: this.cart_unique_token,
        };

        this._api
          .post({ url: apiColletions.get_cart, parameters: json })
          .then((response) => {
            if (response.success) {
              this._user_cart.is_tax_inclusive = response.data.is_tax_included;
              this._user_cart.is_use_item_tax = response.data.is_use_item_tax;
              this.store_taxes = response.data.tax_details;
              response.data.cart.order_details[0].items.forEach((item) => {
                var specification_price = 0;
                item.specifications.forEach((spec) => {
                  specification_price = specification_price + spec.price;
                });
                var total_item_price =
                  item.item_details.price + specification_price;

                if (this._user_cart.is_use_item_tax) {
                  var total_tax = 0;
                  item.tax_details.forEach((tax) => {
                    var tax_index = this._user_cart.total_taxes.findIndex(
                      (x) => x._id === tax._id
                    );

                    if (this._user_cart.is_tax_inclusive) {
                      total_tax =
                        total_item_price -
                        (100 * total_item_price) / (100 + tax.tax);
                    } else {
                      total_tax = total_item_price * tax.tax * 0.01;
                    }

                    tax.tax_amount = total_tax;
                    if (tax_index === -1) {
                      this._user_cart.total_taxes.push(tax);
                    } else {
                      this._user_cart.total_taxes[tax_index].tax_amount =
                        this._user_cart.total_taxes[tax_index].tax_amount +
                        total_tax;
                    }
                  });
                } else {
                  var total_tax = 0;
                  this.store_taxes.forEach((tax) => {
                    var tax_index = this._user_cart.total_taxes.findIndex(
                      (x) => x._id === tax._id
                    );

                    if (this._user_cart.is_tax_inclusive) {
                      total_tax =
                        total_item_price -
                        (100 * total_item_price) / (100 + tax);
                    } else {
                      total_tax = total_item_price * tax.tax * 0.01;
                    }
                    tax.tax_amount = total_tax;
                    if (tax_index === -1) {
                      this._user_cart.total_taxes.push(tax);
                    } else {
                      this._user_cart.total_taxes[tax_index].tax_amount =
                        this._user_cart.total_taxes[tax_index].tax_amount +
                        total_tax;
                    }
                  });
                }
              });

              resolve(response);
            } else {
              resolve(response);
            }
          });
      } catch {
        resolve({ success: false, data: null, code: null });
      }
    });
  }

  private calculateTotalAmount() {
    let total = 0;
    let total_item_tax = 0;
    this._user_cart.cart_data.cart.forEach((product) => {
      product.items.forEach((item) => {
        total = total + item.total_item_price;
        total_item_tax = total_item_tax + item.total_item_tax;
      });
    });
    this._user_cart.total_cart_amount = total;
    this._user_cart.total_item_tax = total_item_tax;
    if (this._user_cart.cart_data.total_item == 0) {
      this.clear_cart();
    } else {
      // this.update_local_cart();
      setTimeout(() => {
        this.add_to_cart();
      }, 1000);
    }
  }

  add_to_cart(
    delivery_type: DELIVERY_TYPE = DELIVERY_TYPE.DELIVERY
  ): Promise<boolean> {
    // var city_id = ''
    // var country_id = this._helper.country_id
    // console.log(delivery_type)
    if (this._helper.is_table_booking || this._helper.is_qr_code_scanned) {
      delivery_type = DELIVERY_TYPE.TABLE;
    }
    // if(this._user_cart.cart_data.city_id){
    var city_id = this._helper.city_id;
    var country_id = this._helper.country_id;
    // }

    // let parameters = new FormData
    // parameters.append('user_id', this._authService.user._id)
    // parameters.append('server_token', this._authService.user.server_token)
    // parameters.append('user_type', String(this._authService.user.user_type))
    // parameters.append('cart_id', this._user_cart.cart_data.cart_id)
    // parameters.append('store_id', this._user_cart.cart_data.selectedStoreId)
    // parameters.append('city_id', city_id)
    // parameters.append('country_id', country_id)
    // parameters.append('cart_unique_token', this.cart_unique_token)
    // parameters.append('order_details', this._user_cart.cart_data.cart.toString())
    // parameters.append('destination_addresses', JSON.stringify(this._user_cart.cart_data.destination_addresses))
    // parameters.append('pickup_addresses', JSON.stringify(this._user_cart.cart_data.pickup_addresses))
    // parameters.append('total_cart_price', this._user_cart.total_cart_amount.toString())
    // parameters.append('total_item_tax', this._user_cart.total_item_tax.toString())

    // if(images.length){
    //     parameters.append('image_url', images.toString())
    // }

    let parameters = {
      user_id: null,
      server_token: null,
      user_type: null,
      cart_id: this._user_cart.cart_data.cart_id,
      store_id: this._user_cart.cart_data.selectedStoreId,
      city_id,
      country_id,
      cart_unique_token: this.cart_unique_token,
      order_details: this._user_cart.cart_data.cart,
      destination_addresses: this._user_cart.cart_data.destination_addresses,
      pickup_addresses: this._user_cart.cart_data.pickup_addresses,
      total_cart_price: this._user_cart.total_cart_amount,
      total_item_tax: this._user_cart.total_item_tax,
      tax_details: this._user_cart.total_taxes,
      is_use_item_tax: this._user_cart.is_use_item_tax,
      is_tax_included: this._user_cart.is_tax_inclusive,
      table_no: this._user_cart.table_no,
      no_of_persons: this._user_cart.no_of_persons,
      booking_type: this._user_cart.booking_type,
      delivery_type: delivery_type,
    };

    if (this._authService.user) {
      parameters.user_id = this._authService.user._id;
      parameters.server_token = this._authService.user.server_token;
      parameters.user_type = this._authService.user.user_type;
    }

    return new Promise((resolve, reject) => {
      try {
        this._api
          .post({ url: apiColletions.add_item_in_cart, parameters })
          .then((response) => {

            console.log("CART ADD RESP : "+JSON.stringify(response));

            if (response.success) {
              this._user_cart.cart_data.cart_id = response.data.cart_id;
              this._user_cart.cart_data.city_id = response.data.city_id;
              if (
                delivery_type === DELIVERY_TYPE.DELIVERY ||
                delivery_type === DELIVERY_TYPE.TABLE
              ) {
                this.update_local_cart();
              }
              resolve(true);
            } else {
              resolve(false);
            }
          });
      } catch {
        resolve(false);
      }
    });
  }

  update_local_cart(): Promise<boolean> {
    // console.log('update_local_cart')
    return new Promise((resolve, reject) => {
      try {
        var cartProductItems: ItemModel = new ItemModel();
        var cartProducts: cartProductsModel = new cartProductsModel();
        var cartSpecificationGroups: cartSpecificationGroupsModel =
          new cartSpecificationGroupsModel();

        this.get_cart().then((response) => {
          this._user_cart.total_item_tax = 0;
          this._user_cart.cart_data.cart = [];
          this._user_cart.cart_data.total_item = 0;
          this._user_cart.total_cart_amount = 0;
          this._user_cart.cart_main_item = [];
          // this._user_cart.total_taxes = []

          if (response.success) {
            var res_data = response.data;
            this.currency = res_data.currency;
            // this._user_cart.store_location = res_data.location;
            this._user_cart.cart_data.cart_id = res_data.cart_id;
            this._user_cart.cart_data.city_id = res_data.city_id;
            this._user_cart.cart_data.destination_addresses =
              res_data.destination_addresses;
            this._user_cart.cart_data.pickup_addresses =
              res_data.pickup_addresses;
            this._user_cart.cart_data.selectedStoreId = res_data.store_id;
            let cart_data_res = res_data.cart.order_details;
            this.store_taxes = res_data.tax_details;
            cart_data_res.forEach((cart_product) => {
              cartProducts = JSON.parse(JSON.stringify(cartProducts));
              cartProducts.product_id = cart_product.product_detail._id;
              cartProducts.product_name = cart_product.product_detail.name;
              cartProducts.unique_id = cart_product.product_detail.unique_id;
              cartProducts.total_item_tax = 0;
              let item_array = [];
              let itemPriceTotal = 0;
              cart_product.items.forEach((cart_item) => {
                this._user_cart.cart_main_item.push(cart_item.item_details);
                cartProductItems = JSON.parse(JSON.stringify(cartProductItems));
                cartProductItems.item_id = cart_item.item_details._id;
                cartProductItems.unique_id = cart_item.item_details.unique_id;
                cartProductItems.item_name = cart_item.item_details.name;
                cartProductItems.quantity = cart_item.quantity;
                cartProductItems.image_url = cart_item.item_details.image_url;
                cartProductItems.details = cart_item.item_details.details;
                cartProductItems.item_price = cart_item.item_details.price;
                cartProductItems.tax = cart_item.tax;

                cartProductItems.note_for_item = cart_item.note_for_item;
                let specificationPriceTotal = 0;
                let specification_group_array = [];
                cart_item.specifications.forEach((specification_group) => {
                  cartSpecificationGroups = JSON.parse(
                    JSON.stringify(cartSpecificationGroups)
                  );
                  cartSpecificationGroups.name = specification_group.name;
                  cartSpecificationGroups.type = specification_group.type;
                  cartSpecificationGroups.unique_id =
                    specification_group.unique_id;
                  cartSpecificationGroups.range = specification_group.range;
                  cartSpecificationGroups.max_range =
                    specification_group.max_range;
                  cartSpecificationGroups.modifierId =
                    specification_group.modifierId;
                  cartSpecificationGroups.modifierGroupId =
                    specification_group.modifierGroupId;
                  cartSpecificationGroups.isParentAssociate =
                    specification_group.isParentAssociate;
                  cartSpecificationGroups.isAssociated =
                    specification_group.isAssociated;
                  let specification_array = [];
                  let specification_price = 0;
                  specification_group.list.forEach((specification) => {
                    cart_item.item_details.specifications.forEach(
                      (new_specification_group) => {
                        if (
                          specification_group.unique_id ==
                            new_specification_group.unique_id &&
                          specification_group.modifierId ===
                            new_specification_group.modifierId
                        ) {
                          new_specification_group.list.forEach(
                            (new_specification) => {
                              if (
                                specification.unique_id ==
                                new_specification.unique_id
                              ) {
                                specification.price = new_specification.price;
                                specification_price =
                                  specification_price +
                                  new_specification.price *
                                    specification.quantity;
                                specification_array.push(specification);
                                specificationPriceTotal =
                                  specificationPriceTotal +
                                  new_specification.price *
                                    specification.quantity;
                              }
                            }
                          );
                        }
                      }
                    );
                  });
                  cartSpecificationGroups.price = specification_price;
                  cartSpecificationGroups.list = specification_array;
                  specification_group_array.push(cartSpecificationGroups);
                });

                cartProductItems.specifications = specification_group_array;
                cartProductItems.total_specification_price =
                  specificationPriceTotal;
                cartProductItems.total_item_price =
                  (specificationPriceTotal + cart_item.item_details.price) *
                  cart_item.quantity;

                if (this._user_cart.is_use_item_tax) {
                  cart_item.tax_details.forEach((tax) => {
                    var index = this._user_cart.total_taxes.findIndex(
                      (x) => x._id === tax._id
                    );
                    var tax_amount: Number = 0;
                    if (this._helper.is_tax_inclusive) {
                      tax_amount =
                        cartProductItems.total_item_price -
                        (100 * Number(cartProductItems.total_item_price)) /
                          (100 + Number(tax.tax * cartProductItems.quantity));
                    } else {
                      tax_amount = parseInt(
                        (
                          Number(tax.tax * cartProductItems.quantity) *
                          Number(cartProductItems.total_item_price) *
                          0.01
                        ).toFixed(2)
                      );
                    }

                    if (index === -1) {
                      tax.tax_amount = Number(tax_amount);
                      this._user_cart.total_taxes.push(tax);
                    } else {
                      tax.tax_amount = tax.tax_amount + tax_amount;
                    }
                  });
                }

                // console.log('-------------------')
                if (this._user_cart.is_tax_inclusive) {
                  cartProductItems.total_specification_tax =
                    specificationPriceTotal -
                    (100 * Number(specificationPriceTotal)) /
                      (100 + Number(cartProductItems.tax));
                  cartProductItems.item_tax =
                    cartProductItems.item_price -
                    (100 * Number(cartProductItems.item_price)) /
                      (100 + Number(cartProductItems.tax));
                } else {
                  cartProductItems.total_specification_tax =
                    specificationPriceTotal *
                    Number(cartProductItems.tax) *
                    0.01;
                  cartProductItems.item_tax =
                    Number(cartProductItems.tax) *
                    Number(cartProductItems.item_price) *
                    0.01;
                }
                // console.log(cartProductItems)
                // console.log(cartProductItems.item_tax)

                cartProductItems.total_tax =
                  Number(cartProductItems.item_tax) +
                  Number(cartProductItems.total_specification_tax);
                cartProductItems.total_item_tax =
                  Number(cartProductItems.total_tax) *
                  Number(cartProductItems.quantity);
                cartProducts.total_item_tax += Number(
                  cartProductItems.total_item_tax
                );

                item_array.push(cartProductItems);
                itemPriceTotal =
                  itemPriceTotal +
                  (specificationPriceTotal + cart_item.item_details.price) *
                    cart_item.quantity;
                this._user_cart.cart_data.total_item++;
              });
              cartProducts.items = item_array;
              cartProducts.total_item_price = itemPriceTotal;
              this._user_cart.cart_data.cart.push(cartProducts);
              this._user_cart.total_cart_amount =
                this._user_cart.total_cart_amount + itemPriceTotal;

              this._user_cart.total_item_tax =
                this._user_cart.total_item_tax + cartProducts.total_item_tax;
            });
          }
          this.cartSubject.next(this._user_cart);
          resolve(true);
        });
      } catch {
        resolve(false);
      }
    });
  }

  increase_qty(product_id, itemidx, updated_qty = 1) {
    const product_index = this._user_cart.cart_data.cart.findIndex(
      (_p) => _p.product_id === product_id
    );
    if (product_index != -1) {
      // const item_index = this._user_cart.cart_data.cart[product_index].items.findIndex(_i => _i.item_id === item_id);
      const item_index = itemidx;
      if (item_index != -1) {
        this._user_cart.cart_data.cart[product_index].items[
          item_index
        ].quantity += updated_qty;
        let qty =
          this._user_cart.cart_data.cart[product_index].items[item_index]
            .quantity;
        let item_price =
          this._user_cart.cart_data.cart[product_index].items[item_index]
            .item_price;
        let total_specification_price =
          this._user_cart.cart_data.cart[product_index].items[item_index]
            .total_specification_price;
        this._user_cart.cart_data.cart[product_index].items[
          item_index
        ].total_item_price = (item_price + total_specification_price) * qty;
        this._user_cart.cart_data.cart[product_index].items[
          item_index
        ].total_item_tax =
          this._user_cart.cart_data.cart[product_index].items[item_index]
            .total_tax * qty;
        this.calculateTotalAmount();
      }
    }
  }

  decrease_qty(product_id, itemidx, updated_qty = 1) {
    const product_index = this._user_cart.cart_data.cart.findIndex(
      (_p) => _p.product_id === product_id
    );
    if (product_index != -1) {
      const item_index = itemidx;
      if (item_index != -1) {
        this._user_cart.cart_data.cart[product_index].items[
          item_index
        ].quantity -= updated_qty;
        let qty =
          this._user_cart.cart_data.cart[product_index].items[item_index]
            .quantity;
        if (qty > 0) {
          let item_price =
            this._user_cart.cart_data.cart[product_index].items[item_index]
              .item_price;
          let total_specification_price =
            this._user_cart.cart_data.cart[product_index].items[item_index]
              .total_specification_price;
          this._user_cart.cart_data.cart[product_index].items[
            item_index
          ].total_item_price = (item_price + total_specification_price) * qty;
          this._user_cart.cart_data.cart[product_index].items[
            item_index
          ].total_item_tax =
            this._user_cart.cart_data.cart[product_index].items[item_index]
              .total_tax * qty;
          this.calculateTotalAmount();
        } else {
          this.remove_from_cart(
            product_id,
            this._user_cart.cart_data.cart[product_index].items[item_index]
              .item_id,
            itemidx
          );
        }
      }
    }
  }

  remove_from_cart(product_id, item_id, itemidx = -1) {
    const product_index = this._user_cart.cart_data.cart.findIndex(
      (_p) => _p.product_id === product_id
    );
    if (product_index != -1) {
      let item_index;
      if (itemidx != -1) {
        item_index = itemidx;
      } else {
        item_index = this._user_cart.cart_data.cart[
          product_index
        ].items.findIndex((_i) => _i.item_id === item_id);
      }
      if (item_index != -1) {
        this._user_cart.cart_data.cart[product_index].items.splice(
          item_index,
          1
        );
        if (this._user_cart.cart_data.cart[product_index].items.length <= 0) {
          this._user_cart.cart_data.cart.splice(product_index, 1);
        }
        this._user_cart.cart_data.total_item--;
        this.calculateTotalAmount();
      }
    }
  }

  clear_cart(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      try {
        let json = {
          user_id: this._authService.user._id,
          server_token: this._authService.user.server_token,
          cart_id: this.user_cart.cart_data.cart_id,
          cart_unique_token: this.cart_unique_token,
        };
        this._api
          .post({ url: apiColletions.clear_cart, parameters: json })
          .then((response) => {
            if (response.success) {
              this._user_cart.cart_data = new CartData();
              this._user_cart.total_taxes = [];
              this._user_cart.total_item_tax = 0;
              this._user_cart.total_cart_amount = 0;
              this.cartSubject.next(this._user_cart);
              resolve(true);
            } else {
              resolve(false);
            }
          });
      } catch {
        resolve(false);
      }
    });
  }

  update_address(
    latitude = null,
    longitude = null,
    address = null,
    flat_no = null,
    street = null,
    landmark = null,
    note = null
  ): Promise<boolean> {
    latitude =
      latitude !== null
        ? latitude
        : this._user_cart.cart_data.destination_addresses[0].location[0];
    longitude =
      longitude !== null
        ? longitude
        : this._user_cart.cart_data.destination_addresses[0].location[1];
    address =
      address !== null
        ? address
        : this._user_cart.cart_data.destination_addresses[0].address;
    flat_no =
      flat_no !== null
        ? flat_no
        : this._user_cart.cart_data.destination_addresses[0].flat_no;
    street =
      street !== null
        ? street
        : this._user_cart.cart_data.destination_addresses[0].street;
    landmark =
      landmark !== null
        ? landmark
        : this._user_cart.cart_data.destination_addresses[0].landmark;
    note =
      note !== null
        ? note
        : this._user_cart.cart_data.destination_addresses[0].note;
        

    return new Promise((resolve, reject) => {
      try {
        let json = {
          latitude: latitude,
          longitude: longitude,
          destination_address:
            address !== null
              ? address
              : this._user_cart.cart_data.destination_addresses[0].address,
          destination_addresses: [
            {
              delivery_status: 0,
              address_type: 'destination',
              address: address,
              flat_no: flat_no,
              street: street,
              landmark: landmark,
              city: this._user_cart.cart_data.destination_addresses[0].city,
              location: [latitude, longitude],
              note: note,
              user_type:
                this._user_cart.cart_data.destination_addresses[0].user_type,
              user_details:
                this._user_cart.cart_data.destination_addresses[0].user_details,
            },
          ],
          cart_id: this._user_cart.cart_data.cart_id,
        };
        this._api
          .post({
            url: apiColletions.change_delivery_address,
            parameters: json,
          })
          .then((response) => {
            if (response.success) {
              // Cart
              this._user_cart.cart_data.destination_addresses[0].location = [
                latitude,
                longitude,
              ];
              this._user_cart.cart_data.destination_addresses[0].address =
                address;
              // this.cartSubject.next(this._user_cart);
              resolve(true);
            } else {
              resolve(false);
            }
          });
      } catch {
        resolve(false);
      }
    });
  }

  get_order_cart_invoice(
    total_distance,
    total_time,
    selectedTime,
    tip_amount,
    milisecond: number = 0,
    milisecond1: number = 0,
    delivery_type: DELIVERY_TYPE = DELIVERY_TYPE.DELIVERY,
    vehicle_id = null
  ): Promise<ResponseModel> {
    return new Promise((resolve, reject) => {
      try {
        var city_id = '';
        var country_id = '';
        var taxes = [];
        var total_cart_price = 0;
        var total_cart_amout_without_tax = 0;

        if (delivery_type === DELIVERY_TYPE.COURIER) {
          city_id = this._helper.city_id;
          country_id = this._helper.country_id;
        }

        var totalItemsCount = 0;
        this._user_cart.cart_data.cart.forEach((cart_product) => {
          cart_product.items.forEach((cart_item) => {
            totalItemsCount += cart_item.quantity;
            // taxes.push(cart_item)
          });
        });

        total_cart_amout_without_tax = this._user_cart.total_cart_amount;
        if (this._user_cart.is_tax_inclusive) {
          total_cart_price =
            this._user_cart.total_cart_amount - this._user_cart.total_item_tax;
        } else {
          total_cart_price = this._user_cart.total_cart_amount;
        }

        var parameters = {
          city_id,
          country_id,
          user_id: this._authService.user._id,
          server_token: this._authService.user.server_token,
          store_id: this._user_cart.cart_data.selectedStoreId,
          cart_unique_token: this.cart_unique_token,
          order_type: 7,
          total_cart_price: total_cart_price,
          total_item_count: totalItemsCount,
          total_cart_amout_without_tax,
          is_user_pick_up_order: this._user_cart.is_user_pick_up_order,
          tax_details: this._user_cart.total_taxes,
          total_distance,
          total_time,
          pickup_time:[{from:selectedTime}],
          discount_price:20,
          tip_amount,
          vehicle_id: vehicle_id,
          is_use_item_tax: this._user_cart.is_use_item_tax,
          is_tax_included: this._user_cart.is_tax_inclusive,
          booking_fees: this._tableService.tableCart.booking_fees,
          is_schedule_order: this._user_cart.is_schedule_order,
          order_start_at1: milisecond,
          order_start_at2: milisecond1,
        };

        this._api
          .post({ url: apiColletions.get_order_cart_invoice, parameters })
          .then((response) => {
            if (response.success) {
              this._user_cart.server_time = response.data.server_time;
              resolve(response);
            } else {
              resolve(response);
            }
          });
      } catch {
        resolve(null);
      }
    });
  }

  check_promo(promo_code_name, order_payment_id): Promise<ResponseModel> {
    return new Promise((resolve, reject) => {
      try {
        let json = {
          user_id: this._authService.user._id,
          server_token: this._authService.user.server_token,
          promo_code_name,
          order_payment_id,
        };
        this._api
          .post({ url: apiColletions.apply_promo_code, parameters: json })
          .then((res_data) => {
            resolve(res_data);
          });
      } catch (err) {
        resolve({ data: null, code: null, success: false });
      }
    });
  }

  pay_order_payment(
    is_cash_mode = true,
    order_payment_id,
    payment_id,
    delivery_type: DELIVERY_TYPE = DELIVERY_TYPE.DELIVERY,
    token = ''
  ): Promise<ResponseModel> {
    return new Promise((resolve, reject) => {
      try {
        var store_delivery_id = null;
        var type_delivery = 1;
        if (delivery_type === DELIVERY_TYPE.COURIER) {
          store_delivery_id = this._helper.store_delivery_id;
          type_delivery = DELIVERY_TYPE.COURIER;
        } else if (this._helper.is_table_booking) {
          type_delivery = DELIVERY_TYPE.TABLE;
        }
        var json = {
          user_id: this._authService.user._id,
          server_token: this._authService.user.server_token,
          order_payment_id,
          payment_id,
          is_payment_mode_cash: is_cash_mode,
          store_delivery_id: store_delivery_id,
          delivery_type: type_delivery,
          token,
        };
        this._api
          .post({ url: apiColletions.pay_order_payment, parameters: json })
          .then((response) => {
            resolve(response);
          });
      } catch (err) {
        resolve({ code: null, data: null, success: false });
      }
    });
  }

  create_order(
    order_payment_id,
    milisecond: number = 0,
    milisecond1: number = 0,
    images = [],
    delivery_type = DELIVERY_TYPE.DELIVERY,
    delivery_note: string = '',
    is_allow_contactless_delivery: boolean = false,
    is_bring_change: boolean = false,
    vehicle_id = null,
    is_schedule_order: boolean = false
  ): Promise<boolean> {
    return new Promise((resolve, reject) => {
      try {
        var parameters;
        if (delivery_type === DELIVERY_TYPE.COURIER) 
        {
          parameters = new FormData();
          if (images.length) {
            images.forEach((image, index) =>
              parameters.append('image' + index, image)
            );
          }
          parameters.append('user_id', this._authService.user._id);
          parameters.append(
            'server_token',
            this._authService.user.server_token
          );
          parameters.append('cart_id', this._user_cart.cart_data.cart_id);
          // parameters.append('destination_addresses', this._user_cart.cart_data.destination_addresses.toString())
          parameters.append('order_payment_id', order_payment_id);
          parameters.append(
            'delivery_note',
            this.user_cart.cart_data.destination_addresses[0].note
          );
          parameters.append(
            'pickup_note',
            this.user_cart.cart_data.pickup_addresses[0].note
          );
          parameters.append(
            'delivery_user_name',
            this.user_cart.cart_data.destination_addresses[0].user_details.name
          );
          parameters.append(
            'delivery_user_phone',
            this.user_cart.cart_data.destination_addresses[0].user_details.name
          );
          parameters.append(
            'is_user_pick_up_order',
            this._user_cart.is_user_pick_up_order.toString()
          );
          parameters.append('order_start_at2', milisecond1.toString());
          parameters.append('order_start_at', milisecond.toString());
          parameters.append('delivery_type', 2);
          parameters.append('vehicle_id', vehicle_id);
          parameters.append('is_bring_change', is_bring_change);
          parameters.append('is_schedule_order', is_schedule_order);
        } 
        else 
        {
          this._user_cart.cart_data.destination_addresses[0].note =
            delivery_note;

          parameters = {
            user_id: this._authService.user._id,
            server_token: this._authService.user.server_token,
            cart_id: this._user_cart.cart_data.cart_id,
            destination_addresses:
              this._user_cart.cart_data.destination_addresses,
            order_payment_id,
            delivery_note,
            delivery_user_name: '',
            delivery_user_phone: '',
            is_user_pick_up_order: this._user_cart.is_user_pick_up_order,
            is_schedule_order: this._user_cart.is_schedule_order,
            is_allow_contactless_delivery,
            order_start_at2: milisecond1,
            order_start_at: milisecond,
            delivery_type,
            is_bring_change,
            // delivery_user_name: this._cartService.user_cart.delivery_user_name,
            // delivery_user_phone: this._cartService.user_cart.delivery_user_phone,
          };
        }

        this._api
          .post({ url: apiColletions.create_order, parameters })
          .then((response) => {

            console.log("response : "+ JSON.stringify(response));

            if (response.success) {
              this._user_cart.cart_data = new CartData();
              this._user_cart = new Cart();

              var new_token = this._helper.generate_new_uuid;
              this.cart_unique_token = new_token;
              localStorage.setItem('cart_unique_token', new_token);
              this.cartSubject.next(this._user_cart);
              resolve(true);
            } else {
              resolve(false);
            }
          });
      } catch (err) {
        resolve(false);
      }
    });
  }

  // create_courier_order(
  //   order_payment_id,
  //   create_courier_order,
  //   milisecond: number = 0,
  //   milisecond1: number = 0,
  //   images = [],
  //   delivery_type = DELIVERY_TYPE.DELIVERY,
  //   delivery_note: string = '',
  //   is_allow_contactless_delivery: boolean = false,
  //   is_bring_change: boolean = false,
  //   vehicle_id = null
  // ): Promise<boolean> {
  //   return new Promise((resolve, reject) => {
  //     try {
  //       var parameters;
  //       if (delivery_type === DELIVERY_TYPE.COURIER) {
  //         parameters = new FormData();
  //         if (images.length) {
  //           images.forEach((image, index) =>
  //             parameters.append('image' + index, image)
  //           );
  //         }
  //         parameters.append('user_id', this._authService.user._id);
  //         parameters.append(
  //           'server_token',
  //           this._authService.user.server_token
  //         );
  //         parameters.append('cart_id', this._user_cart.cart_data.cart_id);
  //         // parameters.append('destination_addresses', this._user_cart.cart_data.destination_addresses.toString())
  //         parameters.append('order_payment_id', order_payment_id);
  //         parameters.append(
  //           'delivery_note',
  //           this.user_cart.cart_data.destination_addresses[0].note
  //         );
  //         parameters.append(
  //           'pickup_note',
  //           this.user_cart.cart_data.pickup_addresses[0].note
  //         );
  //         parameters.append(
  //           'delivery_user_name',
  //           this.user_cart.cart_data.destination_addresses[0].user_details.name
  //         );
  //         parameters.append(
  //           'delivery_user_phone',
  //           this.user_cart.cart_data.destination_addresses[0].user_details.name
  //         );
  //         parameters.append(
  //           'is_user_pick_up_order',
  //           this._user_cart.is_user_pick_up_order.toString()
  //         );
  //         parameters.append(
  //           'is_schedule_order',
  //           this._user_cart.is_schedule_order.toString()
  //         );
  //         parameters.append('order_start_at2', milisecond1.toString());
  //         parameters.append('order_start_at', milisecond.toString());
  //         parameters.append('delivery_type', 2);
  //         parameters.append('vehicle_id', vehicle_id);
  //         parameters.append('is_bring_change', is_bring_change);
  //       } else {
  //         this._user_cart.cart_data.destination_addresses[0].note =
  //           delivery_note;

  //         parameters = {
  //           user_id: this._authService.user._id,
  //           server_token: this._authService.user.server_token,
  //           cart_id: this._user_cart.cart_data.cart_id,
  //           destination_addresses:
  //             this._user_cart.cart_data.destination_addresses,
  //           order_payment_id,
  //           delivery_note,
  //           delivery_user_name: '',
  //           delivery_user_phone: '',
  //           is_user_pick_up_order: this._user_cart.is_user_pick_up_order,
  //           is_schedule_order: this._user_cart.is_schedule_order,
  //           is_allow_contactless_delivery,
  //           order_start_at2: milisecond1,
  //           order_start_at: milisecond,
  //           delivery_type,
  //           is_bring_change,
  //           // delivery_user_name: this._cartService.user_cart.delivery_user_name,
  //           // delivery_user_phone: this._cartService.user_cart.delivery_user_phone,
  //         };
  //       }

  //       this._api
  //         .post({ url: apiColletions.create_courier_order, parameters })
  //         .then((response) => {

  //           console.log("response : "+ JSON.stringify(response));

  //           if (response.success) {
  //             this._user_cart.cart_data = new CartData();
  //             this._user_cart = new Cart();

  //             var new_token = this._helper.generate_new_uuid;
  //             this.cart_unique_token = new_token;
  //             localStorage.setItem('cart_unique_token', new_token);
  //             this.cartSubject.next(this._user_cart);
  //             resolve(true);
  //           } else {
  //             resolve(false);
  //           }
  //         });
  //     } catch (err) {
  //       resolve(false);
  //     }
  //   });
  // }

  change_user_wallet_status(is_use_wallet): Promise<Boolean> {
    return new Promise((resolve, reject) => {
      try {
        let json = {
          user_id: this._authService.user._id,
          server_token: this._authService.user.server_token,
          is_use_wallet,
        };
        this._api
          .post({
            url: apiColletions.change_user_wallet_status,
            parameters: json,
          })
          .then((response) => {
            resolve(response.success);
          });
      } catch (err) {
        resolve(false);
      }
    });
  }

  edit_order(json): Promise<any> {
    return new Promise((resolve, reject) => {
      try {
        this._api
          .post({ url: apiColletions.user_update_order, parameters: json })
          .then((response) => {
            resolve(response);
          });
      } catch {
        resolve({});
      }
    });
  }

  get_courier_order_invoice(
    total_distance,
    total_time,
    tip_amount,
    vehicle_id,
    milisecond1,
    schedule_time_selected
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      try {
        var totalItemsCount = 0;
        this._user_cart.cart_data.cart.forEach((cart_product) => {
          cart_product.items.forEach((cart_item) => {
            totalItemsCount += cart_item.quantity;
          });
        });

        var parameters = {
          city_id: this._helper.city_id,
          country_id: this._helper.country_id,
          user_id: this._authService.user._id,
          server_token: this._authService.user.server_token,
          store_id: this._user_cart.cart_data.selectedStoreId,
          cart_unique_token: this.cart_unique_token,
          order_type: 7,
          total_cart_price: this._user_cart.total_cart_amount,
          total_item_count: totalItemsCount,
          is_user_pick_up_order: this._user_cart.is_user_pick_up_order,
          total_distance,
          total_time,
          tip_amount,
          vehicle_id: vehicle_id,
          is_schedule_order: schedule_time_selected,
          order_start_at2: milisecond1,
        };

        console.log('parameters', parameters);
        this._api
          .post({ url: apiColletions.get_courier_order_invoice, parameters })
          .then((response) => {
            if (response.success) {
              this._user_cart.server_time = response.data.server_time;
              resolve(response.data);
            } else {
              console.log(response);
              resolve(new OrderPayment());
            }
          });
      } catch (e) {
        console.log(e);
        resolve(new OrderPayment());
      }
    });
  }
}
