
export var PAYMENT_GATEWAY = {
    "STRIPE":"stripe",
    "CASH":"cash",
    "PAYSTACK":"paystack"
}

export var ORDER_STATE = {
    WAITING_FOR_ACCEPT_STORE: 1,
    STORE_ACCEPTED: 3,
    ORDER_READY: 7,
    DELIVERY_MAN_STARTED_DELIVERY: 19,
    DELIVERY_MAN_ARRIVED_AT_DESTINATION: 21,
    ORDER_COMPLETED: 25,
    DELIVERY_MAN_PICKED_ORDER: 17,

    // CANCELED_BY_USER: 101,
    // STORE_REJECTED: 103,
    // STORE_CANCELLED: 104,
    // STORE_CANCELLED_REQUEST: 105,
    // STORE_PREPARING_ORDER: 5,
    // WAITING_FOR_DELIVERY_MAN: 9,
    // NO_DELIVERY_MAN_FOUND: 109,
    // DELIVERY_MAN_ACCEPTED: 11,
    // DELIVERY_MAN_REJECTED: 111,
    // DELIVERY_MAN_CANCELLED: 112,
    DELIVERY_MAN_COMING: 13,
    DELIVERY_MAN_ARRIVED: 15,
    // DELIVERY_MAN_COMPLETE_DELIVERY: 23,
};

export var DEFAULT_IMAGE  = {
    USER_PROFILE: 'assets/default_images/profile.png',
    // STORE_PROFILE: 'assets/default_images/store.png',
    STORE_PROFILE: 'assets/default_images/new-store.jpg',
    DELIVERY: 'assets/default_images/delivery.png',
    DELIVERY_STORE: 'assets/default_images/store1.png',
    DELIVERY_COURIER: 'assets/default_images/courier.png',
    PRODUCT: 'assets/default_images/default_item_product.png',
    ADS: 'assets/default_images/ads.png',
}

export var PRODUCT_SEO_TAGS = {
    NAME: 'Deliver.sg'
}

export var THEME_NO = {
    THEME_ONE : 1,
    THEME_TWO: 2
}
