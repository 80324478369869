import { Component, OnInit} from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { CommonService } from './services/common.service';
import { Helper } from './shared/helper';
import { HeaderComponent } from './views/layout/header/header.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers:[HeaderComponent]
})
export class AppComponent implements OnInit  {
  constructor(public _helper: Helper,private _commanService:CommonService,private Title:Title){}
  
  ngOnInit(){
    this.Title.setTitle(this._commanService.settings.app_name);
    var link: any = document.querySelector("link[rel~='icon']");
    if (!link) {
        link = document.createElement('link');
        link.rel = 'icon';
        document.getElementsByTagName('head')[0].appendChild(link);
    }else{
      link.href = environment.IMAGE_URL +  'web_images/user_logo.png';
    }
  }

  onActivate(){
    this._helper.loadScript('/assets/js/custom.js')
    window.scroll(0,0);
    this._commanService.is_location_show = this._helper._route.url !== '/';
  }

}
