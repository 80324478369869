import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SetttingModel } from 'src/app/models/setting.model';
import { CommonService, Lang } from 'src/app/services/common.service';
import { Helper } from 'src/app/shared/helper';
import { environment } from "src/environments/environment";

declare var $:any;

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {

  BASE_URL = environment.BASE_URL;
  IMAGE_URL = environment.IMAGE_URL
  langs:Array<Lang> = [];
  selectedLang = 'en';
  setting:SetttingModel = new SetttingModel();
  url: string = ''

  constructor(@Inject(DOCUMENT) private _document: Document, public _helper:Helper,private _commonService:CommonService,public translateService:TranslateService, private renderer: Renderer2) { }

  ngOnInit(): void {

    this.storeLangs().then(()=>{
      this.langs = this._commonService.langs;
    })
    this.loadScript()
    this.selectedLang = this.translateService.currentLang;
    this.setting = this._commonService.settings;
  }


  storeLangs(){
    return new Promise((resolve,rejects)=>{
        this._commonService.get_languages().then(langs=>{
            this._commonService.langs = langs;
            if(this._commonService.langs.length){
              var code_array = [];
              this._commonService.langs.forEach((language) => {
                  code_array.push(language.code)
              })
              this.translateService.addLangs(code_array);
              this.selectedLang = localStorage.getItem('current_lang');
              if(!this.selectedLang){
                this.selectedLang = this._commonService.langs[0].code;
                localStorage.setItem('current_lang',this.selectedLang);
                localStorage.setItem('selected_lang_index', '0');
              }
              if(this.selectedLang == 'ar'){
                $('body').addClass('rtl');
              }else{
                $('body').removeClass('rtl');
              }
              this.translateService.setDefaultLang(this.selectedLang);
              this.translateService.use(this.selectedLang);
              this._helper.current_lang_index = 0
              resolve(true);
            }
          })
    })
}

  change_langauge(lang, index){
    this.selectedLang = lang;
    localStorage.setItem('current_lang',this.selectedLang);
    localStorage.setItem('selected_lang_index',index);
    if(lang == 'ar'){
      $('body').addClass('rtl');
    }else{
      $('body').removeClass('rtl');
    }
    this.translateService.setDefaultLang(lang)
    this.translateService.use(lang)
    window.location.reload()
    this._helper.current_lang_index = index
  }

  loadScript(){
    this._commonService.get_script_pages().then(result => {
      if(result.success){
        let scripts = result.scripts
        scripts.forEach(script_data => {

          setTimeout(() => {
            var script = this.renderer.createElement('script');
            script.type = 'text/javascript';
            script.src = 'test'
            script.innerHTML = script_data.html_data;
            this.renderer.appendChild(this._document.body,script);
          },2000);
        });
      }
    })
  }

}
